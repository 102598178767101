import { format, parseISO, getTime } from 'date-fns';
import { ThemeKey } from 'contexts/ThemeContext';

/**
  Converts an array to a map object
  @param arr The array to convert to map
  @param generateId The function which should be used to generate an id for an
  individual item in the map
*/
export const getMapFromArray = <T>(
  arr: T[],
  generateId: (item: T) => string
): Map<string, T> => {
  return arr.reduce((stack, item) => {
    const id = generateId(item);
    stack.set(id, item);

    return stack;
  }, new Map());
};

export const getArrayFromMap = <T>(map: Map<string, T>): T[] => {
  return Array.from(map, ([, val]) => {
    return val;
  });
};

export const formatConsoleDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

/**
 * Formats a date to the same format as the backend uses
 * (similar to ISO spec, but with higher ms precision).
 */
export const dateToBackendISO = (date: Date): string => {
  const formattedTime = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'");

  return formattedTime;
};

export const getYesterdayDate = (): string => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return dateToBackendISO(yesterday);
};

export const sortByISODate = (a: string, b: string): number => {
  return getTime(parseISO(a)) - getTime(parseISO(b));
};

export const getMappedItemsFromIdArray = <T>(
  ids: string[],
  map: Map<string, T>
): T[] => {
  return ids.reduce<T[]>((stack, id) => {
    const item = map.get(id);

    return item !== undefined ? stack.concat(item) : stack;
  }, []);
};

export const pluralise = (
  num: number,
  singular: string,
  plural: string
): string => {
  const selection = num === 1 ? singular : plural;

  return selection;
};

export const mapToArray = <T>(map: Map<string, T>): T[] => {
  return Array.from(map, ([, val]) => {
    return val;
  });
};

export const capitaliseFirst = (str: string): string => {
  if (str.length === 0) {
    return '';
  }

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getSystemAppearancePreference = (): ThemeKey => {
  if (typeof window === 'undefined') return 'light';
  return window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const serialToSlug = (serial: string): string => {
  return serial.replace(/\s+/g, '-');
};

export const getReadableDate = (date: string | Date): string => {
  return format(new Date(date), 'eee, do MMM yy (HH:mm)');
};

export const secondsToHoursMinsSeconds = (seconds: number): string => {
  const result = new Date(seconds * 1000).toISOString().slice(11, 19);

  return result;
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * Retrieve the entries of an object & preserve the types of the keys and values.
 * @param obj object to retrieve the key and values from
 * @returns array of tuples that contains the key and value.
 */
export const entries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

/**
 * Combines passed functions together to create a single pipeline function.
 * https://blog.logrocket.com/how-to-create-compose-function-typescript/
 * @param functions rest param of functions
 * @returns single combined function
 */
export const pipe = <T extends any[], U>(
  fn1: (...args: T) => U,
  ...fns: Array<(a: U) => U>
) => {
  const piped = fns.reduce(
    (prevFn, nextFn) => (value: U) => nextFn(prevFn(value)),
    (value) => value
  );
  return (...args: T) => piped(fn1(...args));
};
