import { TrackedButton } from './TrackedButton';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  iconClose: {
    fill: '#fff',
  },
};

type Props = {
  children?: React.ReactNode;
  onClick: LoadingButtonProps['onClick'];
};

export const Close = ({ children, onClick }: Props): JSX.Element => {
  return (
    <TrackedButton
      onClick={onClick}
      trackLabel="close"
      color="inherit"
      variant="text"
    >
      {children}
      <CloseIcon sx={styles.iconClose} />
    </TrackedButton>
  );
};
