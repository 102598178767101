import {
  Organization,
  OrganizationsV2,
  Permissions,
  UserPermission,
  UserPermissions,
  User,
} from '@automata/api/apiSchemas';
import { IUser, PermissionLevelsEnum, PermissionsEnum } from './types';

export const organizationsSelector = (
  data: OrganizationsV2
): IUser['organizationsMap'] =>
  new Map<string, Organization>(data.organizations.map((org) => [org.id, org]));

export const permissionsSelector = (
  data: Permissions,
  userPermissions?: UserPermission[]
): IUser['permissions'] => {
  if (!userPermissions) return new Map();

  return new Map(
    userPermissions.map(({ level, permission }) => {
      const permissionContent = data.permissions.find(
        ({ name }) => name === permission
      );
      return [
        permission as PermissionsEnum,
        {
          label: permissionContent?.label ?? '',
          description: permissionContent?.description ?? '',
          level: level as PermissionLevelsEnum,
        },
      ];
    })
  );
};

export const userPermissionsSelector = (
  data: UserPermissions
): UserPermission[] => data.permissions;

export const userSelector = (data: User, userPicture?: string) => ({
  ...data,
  picture: userPicture ?? data.picture,
});
