import { Typography } from '@automata/ui';
import Link from 'next/link';
import { SxStyles } from 'types/global';
import { RouteKey } from 'GlobalRoutes';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useRoute } from 'hooks/useRoute';

const styles = {
  link: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuItem: {
    textWrap: 'wrap',
    px: 1,
  },
} satisfies SxStyles;

export interface Props {
  title: string;
  description: string;
  route: RouteKey;
}

export const SettingsMenuItem = ({
  title,
  description,
  route,
}: Props): JSX.Element | null => {
  const href = useRoute(route);

  return (
    <>
      <Divider variant="middle" />
      <Box m={1}>
        <Link href={href} style={styles.link}>
          <Typography variant="overline" mx={1}>
            {title}
          </Typography>
          <MenuItem sx={styles.menuItem}>
            <Typography variant="subtitle2" color="primary">
              {description}
            </Typography>
          </MenuItem>
        </Link>
      </Box>
    </>
  );
};
