import mixpanel, { Dict } from 'mixpanel-browser';
import autobind from 'auto-bind';
import { env } from './environment/strictEnv';

export class Tracking {
  env = env('NEXT_PUBLIC_ENV');
  token = env('NEXT_PUBLIC_MIXPANEL_TOKEN');

  constructor() {
    autobind(this);
    this.init();
  }

  init() {
    mixpanel.init(this.token, { debug: this.env === 'local' });
  }

  identify = (email: string | undefined, name: string | undefined) => {
    if (email && name) {
      mixpanel.identify(email);
      mixpanel.people.set({
        $first_name: name,
        $email: email,
      });
    }
  };

  alias = (id: string) => {
    mixpanel.alias(id);
  };

  track = (value: string, properties?: Dict) => {
    mixpanel.track(value, properties);
  };
}

export const tracking = new Tracking();
