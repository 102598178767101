import { BodyType } from './types';

export const bodyHasFile = (body: Record<string, unknown>): boolean => {
  return Object.values(body).some((value) => {
    if (Array.isArray(value)) {
      return value.some((item) => item instanceof File || item instanceof Blob);
    }
    return value instanceof File || value instanceof Blob;
  });
};

export const isFileOrBlob = (value: unknown): value is File | Blob =>
  value instanceof File || value instanceof Blob;

export const getName = (value: Blob | File): string | undefined => {
  if (value instanceof File) return value.name;
  return undefined;
};

export const convertBodyToFormData = (
  body: Record<string, unknown>
): FormData => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (isFileOrBlob(item)) {
          formData.append(key, item, getName(item));
        } else {
          formData.append(key, JSON.stringify(item));
        }
      });
    } else if (isFileOrBlob(value)) {
      formData.append(key, value, getName(value));
    } else {
      formData.append(key, JSON.stringify(value));
    }
  });
  return formData;
};

export const processBody = (body: BodyType): BodyInit | undefined => {
  if (!body) return undefined;
  if (body instanceof FormData) return body;
  if (bodyHasFile(body)) return convertBodyToFormData(body);
  return JSON.stringify(body);
};

export const getContentTypeHeader = (
  initialContentTypeHeader: string,
  body: BodyType
): string | undefined => {
  /**
   * As the fetch API is being used, when multipart/form-data is specified
   * the Content-Type header must be deleted so that the browser can set
   * the correct boundary.
   * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
   */
  if (initialContentTypeHeader?.toLowerCase() === 'multipart/form-data')
    return undefined;
  if (body && (body instanceof FormData || bodyHasFile(body))) return undefined;

  if (!initialContentTypeHeader) return 'application/json';
  return initialContentTypeHeader;
};

export const processHeaders = (
  inputHeaders: Record<string, string> = {},
  body: BodyType,
  getContentTypeHeaderParam = getContentTypeHeader
): Headers => {
  const {
    ['Content-Type']: initialContentTypeHeader = '',
    ...headersWithoutContentType
  } = inputHeaders;

  const newContentTypeHeader = getContentTypeHeaderParam(
    initialContentTypeHeader,
    body
  );

  const requestHeaders = new Headers(headersWithoutContentType);
  if (newContentTypeHeader) {
    requestHeaders.set('Content-Type', newContentTypeHeader);
  }

  return requestHeaders;
};

export const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {}
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;

  return (
    url.replace(/\{\w*\}/g, (key) => {
      return pathParams[key.slice(1, -1)] || '';
    }) + query
  );
};
