import winston, { Logger as WinstonLogger } from 'winston';
import { getOptionalEnv } from '../../environment/getProcessEnv';
import { ILogger, LoggingFunction } from './interface';
import { getErrorMessage } from '../error';

export type Dependencies = typeof dependencies;

export const dependencies = {
  winston,
  getOptionalEnv,
};

export class Logger implements ILogger {
  private readonly deps: Dependencies;
  private logger: WinstonLogger;

  constructor(overrides?: Dependencies) {
    this.deps = { ...dependencies, ...overrides };

    const hasLoggerPrettyPrint = Boolean(
      this.deps.getOptionalEnv('NEXT_PUBLIC_HAS_LOGGER_PRETTY_PRINT')
    );

    this.logger = this.deps.winston.createLogger({
      transports: [new this.deps.winston.transports.Console()],
      format: hasLoggerPrettyPrint
        ? this.deps.winston.format.prettyPrint({ colorize: true })
        : this.deps.winston.format.combine(
            this.deps.winston.format.timestamp(),
            this.deps.winston.format.json()
          ),
    });
  }

  info: LoggingFunction = (...args) => {
    const [firstArg, ...restArgs] = args;
    this.logger.info(getErrorMessage(firstArg, 'log'), ...restArgs);
  };

  log: LoggingFunction = (...args) => {
    const [firstArg, ...restArgs] = args;
    this.logger.info(getErrorMessage(firstArg, 'info'), ...restArgs);
  };

  warn: LoggingFunction = (...args) => {
    const [firstArg, ...restArgs] = args;
    this.logger.warn(getErrorMessage(firstArg, 'warn'), ...restArgs);
  };

  error: LoggingFunction = (...args) => {
    const [firstArg, ...restArgs] = args;
    this.logger.error(getErrorMessage(firstArg, 'error'), ...restArgs);
  };
}
