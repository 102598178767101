import { ApiFetcherOptions, BodyType, ErrorWrapper } from './types';
import { processBody, processHeaders, resolveUrl } from './utils';
import { getErrorMessage, isAbortError, logger } from '@automata/utils';

export type { ErrorWrapper } from './types';

export async function apiFetch<
  TData,
  TError,
  TBody extends BodyType,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {}
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
}: ApiFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  try {
    const requestUrl = `/api/request${resolveUrl(
      url,
      queryParams,
      pathParams
    )}`;
    const response = await window.fetch(requestUrl, {
      signal,
      method: method.toUpperCase(),
      body: processBody(body),
      headers: processHeaders(headers, body),
    });

    if (!response.ok) {
      let error: ErrorWrapper<TError>;
      try {
        error = await response.json();
      } catch (e) {
        error = {
          status: 'unknown' as const,
          payload:
            e instanceof Error
              ? `Unexpected error (${e.message})`
              : 'Unexpected error',
        };
      }

      throw error;
    }

    const payload = await response.text();
    if (response.headers.get('content-type')?.includes('json')) {
      if (payload.length > 0) {
        return JSON.parse(payload);
      }
    }

    // if it is not a json response, assume it is a blob and cast it to TData
    return payload as unknown as TData;
  } catch (e) {
    /**
     * Log the error to the console if it is not an AbortController error
     * https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
     */
    if (!isAbortError(e)) logger.error(e);

    if (e instanceof Error) throw e;
    throw new Error(getErrorMessage(e, 'apiFetcher failed to fetch data'));
  }
}
