import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CentredNotificationCard } from 'components/Cards/CentredNotificationCard';
import { TrackedButton } from '@automata/ui';
import Link from 'next/link';

interface ErrorCardProps {
  title?: string;
  details?: string;
}

export const ErrorCard = ({ title, details }: ErrorCardProps): JSX.Element => {
  return (
    <CentredNotificationCard>
      <Typography variant="h5" color="textSecondary">
        {title ?? 'Error'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {details ??
          'An error occurred but no details were provided. Contact Automata.'}
      </Typography>
      <Box mt={3}>
        <TrackedButton
          trackLabel="error.logout"
          fullWidth={false}
          variant="contained"
          type="button"
          LinkComponent={Link}
          href="/api/auth/logout"
        >
          Logout
        </TrackedButton>
      </Box>
    </CentredNotificationCard>
  );
};
