import { createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';
import { Roboto_Flex, Roboto_Mono } from 'next/font/google';
import {
  accent1,
  accent2,
  grey,
  primary,
  secondary,
  semantic,
  common,
  categorical,
} from './colors';
import '@mui/x-data-grid-pro/themeAugmentation';

const robotoFlex = Roboto_Flex({
  subsets: ['latin'],
});
const robotoMono = Roboto_Mono({
  subsets: ['latin'],
});

export const defaultTheme = createTheme({
  typography: {
    fontFamily: robotoFlex.style.fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 56,
      letterSpacing: '-1.5px',
      fontWeight: 500,
    },
    h2: {
      fontSize: 40,
      letterSpacing: '-0.5px',
      fontWeight: 500,
    },
    h3: {
      fontSize: 32,
      letterSpacing: '0px',
      fontWeight: 500,
    },
    h4: {
      fontSize: 28,
      letterSpacing: '-0.16px',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: 24,
      letterSpacing: '0px',
      fontWeight: 400,
    },
    h6: {
      fontSize: 20,
      letterSpacing: '0.15px',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: '0.15px',
      fontWeight: 580,
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: '0.1px',
      fontWeight: 580,
    },
    body1: {
      fontSize: 16,
      letterSpacing: '0.15px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    button: {
      small: {
        fontSize: 13,
        textTransform: 'uppercase',
        letterSpacing: '0.46px',
        fontWeight: 500,
      },
      medium: {
        fontSize: 14,
        textTransform: 'uppercase',
        letterSpacing: '0.4px',
        fontWeight: 500,
      },
      large: {
        fontSize: 15,
        textTransform: 'uppercase',
        letterSpacing: '0.46px',
        fontWeight: 500,
      },
    },
    caption: {
      fontSize: 12,
      lineHeight: '14px',
      letterSpacing: '0.4px',
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      lineHeight: '100%',
      letterSpacing: '1px',
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: robotoMono.style.fontFamily,
    },
  },
  palette: {
    primary: {
      light: primary[200],
      main: primary[600],
      dark: primary[900],
      ...primary,
    },
    secondary: {
      light: secondary[200],
      main: secondary[600],
      dark: secondary[900],
      ...secondary,
    },
    error: semantic.error,
    warning: semantic.warning,
    success: semantic.success,
    info: semantic.info,
    accent1: {
      light: accent1[200],
      main: accent1[600],
      dark: accent1[900],
      ...accent1,
    },
    accent2: {
      light: accent2[200],
      main: accent2[600],
      dark: accent2[900],
      ...accent2,
    },
    // TODO: Remove grey from palette
    grey: { ...grey },
    neutral: {
      light: grey[200],
      main: grey[800],
      dark: grey[900],
      ...grey,
    },
    divider: 'rgba(0, 0, 0, 0.23)',
    background: { default: '#FFFFFF' },
    action: {
      active: '#0000008A',
      hover: '#0000000A',
      selected: '#00000014',
      disabled: '#00000042',
      disabledBackground: '#0000001F',
      focus: '#0000001F',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
      disabled: '#00000061',
    },
    common: {
      black: common.light.main,
      white: common.dark.main,
    },
    ...categorical,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '& .MuiAccordionSummary-content': {
            margin: '12px 0!important',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: '48px!important',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-row ': {
            borderTop: '1px solid #E0E0E0',
          },
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-rowReorderCellContainer ': {
            minWidth: '30px',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            boxShadow: 'none',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .hideRightSeparator .MuiDataGrid-columnSeparator--sideRight': {
            visibility: 'hidden',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontFamily: robotoMono.style.fontFamily,
            textTransform: 'uppercase',
            fontSize: '10px',
            letterSpacing: '1px',
            fontWeight: 500,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: '0.5rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0.5rem',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          '&:hover': {
            backgroundColor: '#2264C2',
          },
          '&:active': {
            backgroundColor: '#414141',
          },
          '&:disabled': {
            backgroundColor: '#E4E4E4',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[600],
          borderRadius: '20px',
        },
        '*::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      }),
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
});

export const darkTheme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    mode: 'dark',
    primary: {
      main: amber[600],
      contrastText: defaultTheme.palette.common.white,
    },
    secondary: {
      main: amber[600],
      contrastText: defaultTheme.palette.common.white,
    },
    background: {
      paper: '#1f1f37',
      default: '#14142b',
    },
    grey: { 100: '#757575' },
    divider: defaultTheme.palette.common.white,
  },
});
