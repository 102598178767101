import { createSvgIcon } from '@mui/material/utils';

export const DriverIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6H6V18H18V6ZM4 4V20H20V4H4Z"
      fill="#414141"
    />
    <path d="M6 2H8V4H6V2Z" fill="#414141" />
    <path d="M6 20H8V22H6V20Z" fill="#414141" />
    <path d="M2 18L2 16H4V18H2Z" fill="#414141" />
    <path d="M20 18V16H22V18H20Z" fill="#414141" />
    <path d="M11 2H13V4H11V2Z" fill="#414141" />
    <path d="M11 20H13V22H11V20Z" fill="#414141" />
    <path d="M2 13L2 11H4V13H2Z" fill="#414141" />
    <path d="M20 13V11H22V13H20Z" fill="#414141" />
    <path d="M16 2H18V4H16V2Z" fill="#414141" />
    <path d="M16 20H18V22H16V20Z" fill="#414141" />
    <path d="M2 8L2 6H4V8H2Z" fill="#414141" />
    <path d="M20 8V6H22V8H20Z" fill="#414141" />
    <path
      d="M11.9268 13.8911L13.2583 9H14.9854L12.8276 15.3984H11.7114L11.9268 13.8911ZM10.7183 9L12.0498 13.8911L12.2739 15.3984H11.1445L9 9H10.7183Z"
      fill="#414141"
    />
  </svg>,
  'Driver'
);
