import { OptionsObject } from '@automata/rexports/notistack';

type SnackBar = {
  message: string;
  options: OptionsObject;
};

type Snackbars = {
  socketDisconnect: SnackBar;
  socketConnecting: SnackBar;
  networkDisconnect: SnackBar;
  releaseDetected: SnackBar;
};

export const INTERNAL_ERROR_MESSAGE =
  'Sorry, something went wrong, please try again.';

export const snackbars: Snackbars = {
  socketDisconnect: {
    message: 'There was a problem connecting a socket. Please try refreshing.',
    options: {
      persist: true,
      variant: 'error',
      preventDuplicate: true,
      key: 'socketError',
    },
  },
  socketConnecting: {
    message: 'Establishing a connection.',
    options: {
      persist: true,
      variant: 'warning',
      preventDuplicate: true,
      key: 'socketConnecting',
    },
  },
  networkDisconnect: {
    message: 'Device has been offline, refresh to see updates',
    options: {
      variant: 'warning',
      persist: true,
      preventDuplicate: true,
      key: 'networkDisconnect',
    },
  },
  releaseDetected: {
    message: 'New release is available',
    options: {
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      key: 'release',
    },
  },
};
