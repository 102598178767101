import { styled } from '@mui/material/styles';
import {
  TrackedIconButton,
  TrackingButtonProps,
} from '../../TrackedIconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { forwardRef, useId, useImperativeHandle, useRef } from 'react';
import { VisuallyHidden } from '../../..';

const VisuallyHiddenInput = styled('input')`
  position: absolute;
  width: 1;
  height: 1;
  bottom: 0;
  left: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
`;

export type UploadButtonProps = Omit<
  TrackingButtonProps,
  'onChange' | 'ref'
> & {
  onChange: (file: File) => void;
  trackLabel: string;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'accept'>;

export type UploadButtonHandle = {
  reset: () => void;
};

export const UploadButton = forwardRef<UploadButtonHandle, UploadButtonProps>(
  function Forwarded(
    { onChange, trackLabel, accept, ...trackingButtonProps },
    ref
  ) {
    const inputRef = useRef<HTMLInputElement>(null);
    const fileInputId = `upload-file-input-${useId()}`;

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          if (inputRef.current) inputRef.current.value = '';
        },
      }),
      []
    );

    return (
      <>
        <VisuallyHiddenInput
          ref={inputRef}
          id={fileInputId}
          type="file"
          accept={accept}
          disabled={trackingButtonProps.disabled}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) onChange(file);
          }}
          data-testid="upload-file-input"
        />

        <label htmlFor={fileInputId}>
          <VisuallyHidden>Upload file</VisuallyHidden>
          <TrackedIconButton
            aria-label="upload"
            component="span"
            trackLabel={trackLabel}
            {...trackingButtonProps}
          >
            <FileUploadOutlinedIcon />
          </TrackedIconButton>
        </label>
      </>
    );
  }
);
