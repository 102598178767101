import Popover from '@mui/material/Popover';
import { ReplanPopoverContent } from './ReplanPopoverContent';

export type ReplanPopoverProps = {
  title: string;
  content: string;
  previousDataHeader: string;
  previousDataContent: string;
  currentDataHeader: string;
  currentDataContent: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  direction?: 'column' | 'row';
};

export const ReplanPopover = ({
  title,
  content,
  previousDataHeader,
  previousDataContent,
  currentDataHeader,
  currentDataContent,
  anchorEl,
  onClose,
  direction = 'column',
}: ReplanPopoverProps) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ sx: { width: '321px', padding: 2, ml: 1 } }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ReplanPopoverContent
        title={title}
        content={content}
        previousDataHeader={previousDataHeader}
        previousDataContent={previousDataContent}
        currentDataHeader={currentDataHeader}
        currentDataContent={currentDataContent}
        direction={direction}
      />
    </Popover>
  );
};
