import useUserV2 from './useUserV2';

export const useWorkspaceID = (): string | undefined => {
  const {
    data,
    // isLoading,
    // error
  } = useUserV2();

  // todo: handle loading/error

  return data?.currentWorkspace.id;
};
