import { Organization } from '@automata/api/apiSchemas';
import { LocalStorage } from 'utils/localStorage';
import { AppState, IUser } from './types';
import { isEmptyObject } from '@automata/utils';

export const getCurrentOrganization = (
  organizations: IUser['organizationsMap'],
  externalOrgId: string
): IUser['currentOrganization'] => {
  const org = Array.from(organizations.values()).find(
    ({ externalID }) => externalID === externalOrgId
  );
  if (!org) throw new Error('No organization found');
  return org;
};

export const getDefaultOrganization = (
  organizations: Map<string, Organization>,
  storedOrgId = localStorage.getItem(LocalStorage.ORG)
): Organization => {
  if (!organizations.size) throw new Error('No organizations exist');

  const organizationsArray = Array.from(organizations.values());

  const pathsArray = window.location.pathname.split('/');
  const [_empty, slugOrId] = pathsArray;

  // FIXME: Quite brittle here
  if (slugOrId) {
    const org = organizationsArray.find(
      ({ id, slug }) => id === slugOrId || slug === slugOrId
    );
    if (org) return org;
  }

  if (storedOrgId) {
    const org = organizationsArray.find(({ id }) => id === storedOrgId);
    if (org) return org;
  }

  return organizationsArray[0];
};

export const getPathWithQuery = (
  path: string,
  query?: Record<string, string>
): string => {
  if (!query) return path;
  if (isEmptyObject(query)) return path;
  const params = new URLSearchParams(query);
  return `${path}?${params}`;
};

export const getReturnToPath = (
  appState: AppState | undefined,
  deps = { getPathWithQuery }
): string | null => {
  if (!appState) return null;
  const { redirectPath, ...otherAppStateProps } = appState;
  if (!redirectPath) return null;
  return deps.getPathWithQuery(redirectPath, otherAppStateProps);
};

export const handleReauthenticate = async (
  currentOrgExternalId: string,
  appState?: AppState,
  deps = { logoutUrl: '/api/auth/logout' }
): Promise<void> => {
  const returnToPathWithParams = getReturnToPath(appState);
  const params = new URLSearchParams({
    externalOrgId: currentOrgExternalId,
    ...(returnToPathWithParams ? { returnTo: returnToPathWithParams } : {}),
  });

  await fetch(deps.logoutUrl, { mode: 'no-cors' });
  window.location.href = `/api/auth/login?${params}`;
};

export const handleSwitchOrganization = (
  externalOrgId: string,
  deps = { loginUrl: '/api/auth/login' }
): void => {
  const params = new URLSearchParams({ externalOrgId });
  window.location.href = `${deps.loginUrl}?${params}`;
};
