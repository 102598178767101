import { forwardRef } from 'react';
import { Typography } from '../Typography';
import { defaultTheme } from '@automata/mui-theme';
import Stack from '@mui/material/Stack';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { v4 as uuidv4 } from 'uuid';

export type TextFieldProps = Omit<MuiTextFieldProps, 'size'>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function forwarded({ id = `textField-${uuidv4()}`, label, ...props }, ref) {
    return (
      <Stack width={props.fullWidth ? '100%' : 'inherit'} spacing={0.5}>
        <InputLabel htmlFor={id}>
          <Typography
            variant="overline"
            color={defaultTheme.palette.text.primary}
          >
            {label}
          </Typography>
        </InputLabel>
        <MuiTextField id={id} {...props} ref={ref} size="small" />
      </Stack>
    );
  }
);
