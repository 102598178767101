import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { useResizeDetector } from 'react-resize-detector';
import { Typography, TypographyProps } from '../..';

type TooltipProps = {
  children: string | number;
  typographyProps?: TypographyProps;
  tooltipProps?: Omit<MuiTooltipProps, 'title' | 'children'>;
};

/**
 * Renders a Typography component and a tooltip that only shows when its content overflows.
 * NOTE: The parent component must have a defined width for the tooltip to work correctly (minWidth = 0 for flex containers).
 * @example
 * <Tooltip
 *   typographyProps={{ variant: 'h6' }}
 *   tooltipProps={{ placement: 'right-end' }}
 * >
 *   Hover me, I am very long and display a tooltip
 * </Tooltip>
 *
 * @param {TypographyProps} typographyProps - The props for the Typography component.
 * @param {Omit<MuiTooltipProps, 'title' | 'children'>} tooltipProps - The props for the Tooltip component.
 * @param {string} props.children - Only accepts a string as children.
 */
export const Tooltip = ({
  children,
  typographyProps,
  tooltipProps,
}: TooltipProps): JSX.Element => {
  const { width = 0, ref } = useResizeDetector<HTMLDivElement>();
  const isOverflowing = ref.current && ref.current.scrollWidth > width;

  return (
    <MuiTooltip title={isOverflowing ? children : null} {...tooltipProps}>
      <Typography ref={ref} {...typographyProps} noWrap>
        {children}
      </Typography>
    </MuiTooltip>
  );
};
