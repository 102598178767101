import Stack from '@mui/material/Stack';
import { Typography } from '../..';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import LinkOffOutlined from '@mui/icons-material/LinkOffOutlined';
import { defaultTheme } from '@automata/mui-theme';

export interface NetworkStatusIndicatorProps {
  isOnline: boolean;
}

export const NetworkStatusIndicator = ({
  isOnline,
}: NetworkStatusIndicatorProps): JSX.Element => {
  if (isOnline) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <LinkOutlined
          sx={{ color: defaultTheme.palette.primary.main }}
          aria-label="online icon"
        />
        <Typography variant="subtitle2">Online</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <LinkOffOutlined
        sx={{ color: defaultTheme.palette.text.secondary }}
        aria-label="offline icon"
      />
      <Typography variant="subtitle2">Offline</Typography>
    </Stack>
  );
};
