import { env } from '@automata/utils';

export const GlobalRoutes = {
  notFound: '/not-found',
  authorize: env('NEXT_PUBLIC_AUTH0_CALLBACK'),
  home: '/',
  homeOrg: '/:org/',
  overhead: '/:org/overhead',
  overheadID: '/:org/overhead/:id',
  operator: '/:org/manager',
  cloudHealth: '/:org/cloud-health',
  workflow: '/:org/workflows',
  protocolDesigner: '/:org/protocol-designer',
  runhistory: '/:org/run-history',
  runhistoryID: '/:org/run-history/:id',
  users: '/:org/users',
  auditLogs: '/:org/auditLogs',
} as const satisfies Record<string, string>;

export type RouteKey = keyof typeof GlobalRoutes;
