import { ComponentType, MouseEvent, forwardRef, useCallback } from 'react';
import { IconButtonProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { tracking } from '@automata/utils';
import { Dict } from 'mixpanel-browser';

interface ExtendedTrackingProps {
  /**
   * [screen or dashboard].[optional category].[action]
   * e.g. deviceDashboard.control.start
   */
  trackLabel: string;
  /**
   * If Group or Device specific, use ID
   */
  trackProps?: Dict;
  onClick?: IconButtonProps['onClick'];
  component?: keyof JSX.IntrinsicElements | ComponentType;
}

export type TrackedIconButtonProps = IconButtonProps & ExtendedTrackingProps;

export const TrackedIconButton = forwardRef<
  HTMLButtonElement,
  TrackedIconButtonProps
>(function Forwarded(props, ref): JSX.Element {
  const { children, trackLabel, trackProps, onClick, ...buttonProps } = props;
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      tracking.track(trackLabel, trackProps);
    },
    [onClick, trackLabel, trackProps]
  );

  return (
    <IconButton
      ref={ref}
      data-testid={trackLabel}
      onClick={handleClick}
      {...buttonProps}
    >
      {children}
    </IconButton>
  );
});
