import { styled } from '@mui/material';
import MUIContainer from '@mui/material/Container';

const Container = styled(MUIContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${(p) => p.theme.spacing(4)};
  padding-bottom: ${(p) => p.theme.spacing(2)};
`;

export type PageContainerProps = {
  children: React.ReactNode;
};

export const PageContainer = ({
  children,
}: PageContainerProps): JSX.Element => {
  return <Container maxWidth="xxl">{children}</Container>;
};
