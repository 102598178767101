import { getErrorMessage, logger } from '@automata/utils';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useEffect, useRef } from 'react';

export interface ErrorDisplayProps {
  error: unknown;
  userMessage: string;
  logToConsole?: boolean;
  title?: string;
}

export const ErrorDisplay = ({
  error,
  userMessage,
  logToConsole = false,
  title = 'Error',
}: ErrorDisplayProps): JSX.Element | null => {
  const errorHasBeenLogged = useRef(false);

  useEffect(() => {
    if (logToConsole && !errorHasBeenLogged.current) {
      const errorMessage = getErrorMessage(error, userMessage);
      logger.error(errorMessage, { error });
      errorHasBeenLogged.current = true;
    }
  }, [error, userMessage, logToConsole]);

  return (
    <Alert severity="error">
      <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
      {userMessage}
    </Alert>
  );
};
