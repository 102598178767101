import { z } from 'zod';

export const automataAuth0OrganizationSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  display_name: z.string().optional(),
  branding: z
    .object({
      logo_url: z.string().optional(),
      colors: z
        .object({
          page_background: z.string(),
          primary: z.string(),
        })
        .optional(),
    })
    .optional(),
  metadata: z
    .object({ use_mfa: z.enum(['true', 'false']).optional() })
    .and(z.record(z.string()))
    .optional(),
});
export type AutomataAuth0Organization = z.infer<
  typeof automataAuth0OrganizationSchema
>;

export const automataAuth0UserSchema = z.object({
  'https://linq.cloud/roles': z.array(z.unknown()),
  'https://linq.cloud/organizations': z.array(automataAuth0OrganizationSchema),
  given_name: z.string().optional(),
  family_name: z.string().optional(),
  nickname: z.string().optional(),
  name: z.string().optional(),
  picture: z.string().optional(),
  locale: z.string().optional(),
  updated_at: z.string().optional(),
  email: z.string().optional(),
  email_verified: z.boolean().optional(),
  sub: z.string().optional(),
  sid: z.string().optional(),
  org_id: z.string(),
});
export type AutomataAuth0User = z.infer<typeof automataAuth0UserSchema>;
