import { createContext, useCallback, useState } from 'react';
import { darkTheme, defaultTheme } from '@automata/mui-theme';
import { getSystemAppearancePreference } from 'utils/helpers';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { LocalStorage } from 'utils/localStorage';
import { EnvEnum } from 'utils/envEnum';
import { env } from '@automata/utils';

export type ThemeKey = 'dark' | 'light';
interface ContextProps {
  mode: ThemeKey;
  setMode: (mode: ThemeKey) => void;
}

export const ThemeContext = createContext<ContextProps>({
  mode: getSystemAppearancePreference(),
  setMode: () => {
    return undefined;
  },
});

const themes: Record<ThemeKey, Theme> = {
  light: defaultTheme,
  dark: darkTheme,
};

export const CustomThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const isDevEnv =
    env('NEXT_PUBLIC_ENV') === EnvEnum.DEV ||
    env('NEXT_PUBLIC_ENV') === EnvEnum.LOCAL;
  const darkModeEnabled = isDevEnv;

  const getSavedMode = useCallback((): ThemeKey => {
    if (typeof localStorage === 'undefined') return 'light';
    if (!darkModeEnabled) return 'light';

    return (
      (localStorage.getItem(LocalStorage.THEME) as ThemeKey | null) ?? 'light'
    );
  }, [darkModeEnabled]);

  const [mode, _setMode] = useState<ThemeKey>(getSavedMode());

  const setMode = useCallback((_mode: ThemeKey) => {
    localStorage.setItem(LocalStorage.THEME, _mode);
    _setMode(_mode);
  }, []);

  return (
    <ThemeContext.Provider value={{ mode, setMode }}>
      <ThemeProvider theme={themes[mode]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
