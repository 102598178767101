import { useState, useCallback, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Button, Typography, Select } from '@automata/ui';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { logger, tracking } from '@automata/utils';
import { getArrayFromMap } from 'utils/helpers';
import Link from 'next/link';
import useUserV2 from 'hooks/useUserV2';
import { SxStyles } from 'types/global';
import { useSnackbar } from '@automata/rexports/notistack';

const styles = {
  popover: {
    '& .MuiPopover-paper': {
      paddingBlock: 2,
      paddingInline: 2,
    },
  },
  divider: {
    marginBlock: 2,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  userEmail: {
    fontWeight: 'bold',
    marginLeft: 1,
  },
  avatar: {
    width: 32,
    height: 32,
  },
} satisfies SxStyles;

export const ProfileNavItem = (): JSX.Element | null => {
  const {
    data: user,
    error: userError,
    isLoading: userIsLoading,
  } = useUserV2();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      tracking.track('nav.profile.open');
    },
    []
  );

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
    tracking.track('nav.profile.close');
  }, []);

  const handleOrgMenuItemClick = useCallback(
    (orgId: string) => {
      if (!user) return;
      if (orgId === user.currentOrganization.id) return;
      const targetOrg = user.organizationsMap.get(orgId);
      if (!targetOrg) return;
      onMenuClose();
      user.switchOrganization(targetOrg.externalID);
    },
    [onMenuClose, user]
  );

  useEffect(() => {
    if (userError) {
      logger.error(userError);
      enqueueSnackbar('Failed to fetch user', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, userError]);

  if (userIsLoading || userError) return null;

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onMenuButtonClick}
        color="inherit"
        data-cy="nav-profile"
        size="large"
      >
        <Avatar
          src={user.userData.picture}
          data-cy="user-avatar"
          sx={styles.avatar}
        />
      </IconButton>
      <Popover
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={onMenuClose}
        sx={styles.popover}
      >
        <Box data-cy="user-username" sx={styles.user}>
          <Avatar src={user.userData.picture} data-cy="user-avatar" />
          <Typography sx={styles.userEmail}>{user.userData.email}</Typography>
        </Box>

        <Divider sx={styles.divider} />

        <Select label="Organisation" value={user.currentOrganization.id}>
          {getArrayFromMap(user.organizationsMap).map((org) => (
            <MenuItem
              key={org.id}
              value={org.id}
              onClick={() => handleOrgMenuItemClick(org.id)}
            >
              {org.name}
            </MenuItem>
          ))}
        </Select>

        <Divider sx={styles.divider} />

        <Button
          variant="contained"
          href="/api/auth/logout"
          data-cy="user-logout"
          LinkComponent={Link}
        >
          Log out
        </Button>
      </Popover>
    </>
  );
};
