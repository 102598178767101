import { useEffect } from 'react';
import { Typography } from '@automata/ui';
import { env, logger } from '@automata/utils';
import { useGetVersion } from '@automata/api/apiComponents';
import { useSnackbar } from 'hooks/useSnackbar';

export const Versions = (): JSX.Element => {
  const { data: version, error: versionError } = useGetVersion({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (versionError) {
      logger.error(versionError);
      enqueueSnackbar('Failed to fetch app versions', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, versionError]);

  return (
    <>
      <Typography variant="overline" my={1}>
        Version
      </Typography>
      {versionError && (
        <Typography variant="subtitle2">
          Unable to retrieve App version
        </Typography>
      )}
      {version && (
        <Typography variant="subtitle2">
          App: {env('NEXT_PUBLIC_VERSION')} / Types: v{version.open_api_version}{' '}
          / API: {version.app_version?.split('-')[0]}
        </Typography>
      )}
    </>
  );
};
