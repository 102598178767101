interface QueryResultSuccess<TData> {
  data: TData;
  error: null;
  isLoading: false;
}

export interface QueryResultError<TError> {
  data: undefined;
  error: TError;
  isLoading: false;
}

interface QueryResultLoading {
  data: undefined;
  error: null;
  isLoading: true;
}

export type QueryResult<TData = any, TError = unknown> =
  | QueryResultSuccess<TData>
  | QueryResultError<TError>
  | QueryResultLoading;

export const getLoadingState = (): QueryResultLoading => ({
  data: undefined,
  error: null,
  isLoading: true,
});

export const getSuccessState = <TData>(
  data: TData
): QueryResultSuccess<TData> => ({
  data,
  error: null,
  isLoading: false,
});

export const getErrorState = <TError>(
  error: TError
): QueryResultError<TError> => ({
  data: undefined,
  error,
  isLoading: false,
});
