import { isClientSide } from './clientSide';

const isServerSideDeps = {
  isClientSide,
};

export const isServerSide = (overrides?: typeof isServerSideDeps) => {
  const deps = { ...isServerSideDeps, ...overrides };

  return !deps.isClientSide();
};
