import { logger } from '@automata/utils';
import * as Ably from 'ably';
import { fetchExchangeAuth0AblyToken } from '@automata/api/apiComponents';

export const ablyAuthCallback = async (
  data: Ably.Types.TokenParams,
  cb: (
    error: string | Ably.Types.ErrorInfo | null,
    tokenRequestOrDetails:
      | string
      | Ably.Types.TokenDetails
      | Ably.Types.TokenRequest
      | null
  ) => void
) => {
  try {
    const ablyToken = await fetchExchangeAuth0AblyToken({});
    const tokenRequest = JSON.parse(ablyToken.token);
    cb(null, tokenRequest);
  } catch (e) {
    logger.error('ably token failure', { error: e });
    cb(`${e}`, null);
  }
};
