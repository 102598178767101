import { Collapse, PaletteColor, styled, useTheme } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { PaletteOptions } from '@mui/material/styles';
import { ReactNode, useId } from 'react';
import { Typography } from '../Typography';

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'levelColor',
})<{ levelColor: string }>`
  padding: ${(p) => p.theme.spacing(2)};
  margin: ${(p) => p.theme.spacing(2)} 0;
  border-width: 8px 1px 1px 1px;
  border-style: solid;
  border-color: ${(p) => p.levelColor};
  background-color: white;
`;

type Level = Extract<keyof PaletteOptions, 'error' | 'warning'>;

export interface AlertPanelProps {
  title?: string;
  isExpanded: boolean;
  level: Level;
  children: ReactNode;
  'aria-label'?: string;
  childSpacing?: StackProps['spacing'];
}

export const AlertPanel = ({
  title,
  isExpanded,
  children,
  level,
  ['aria-label']: ariaLabel,
  childSpacing = 2,
}: AlertPanelProps): JSX.Element | null => {
  const theme = useTheme();

  const colorMap = {
    error: theme.palette.error,
    warning: theme.palette.warning,
  } satisfies Record<Level, PaletteColor>;

  const muiPaletteColor = colorMap[level].main;

  const id = useId();
  const titleId = `${id}-title`;

  const conditionalAriaConfig:
    | { 'aria-label': string }
    | { 'aria-labelledby': string } = (() => {
    if (ariaLabel) return { 'aria-label': ariaLabel };
    if (title) return { 'aria-labelledby': titleId };
    return { 'aria-label': 'alert panel' };
  })();

  return (
    <Collapse
      in={isExpanded}
      easing={theme.transitions.easing.easeInOut}
      timeout={750}
    >
      <Container
        role="alertdialog"
        {...conditionalAriaConfig}
        levelColor={muiPaletteColor}
      >
        {title && (
          <Typography variant="h6" mb={2} id={titleId}>
            {title}
          </Typography>
        )}
        <Stack spacing={childSpacing}>{children}</Stack>
      </Container>
    </Collapse>
  );
};
