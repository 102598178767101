import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useVariant } from 'hooks/unleashHooks';

export const UpcomingMaintenanceBanner = (): JSX.Element => {
  const variant = useVariant(
    'fe.informational_maintenance_update_scheduled_for'
  );

  // updateTimestamp is a string in the format 'Wednesday at 10:00 AM'
  const updateTimestamp = variant?.payload?.value;

  return (
    <Alert severity="warning">
      <AlertTitle>
        <strong>Upcoming software update</strong>
      </AlertTitle>
      {`A LINQ software update is scheduled for ${
        updateTimestamp ?? 'the next 48 hours'
      }. Please delay any planned workflow runs until after the update is completed${
        updateTimestamp ? ` on ${updateTimestamp} approximately` : ''
      }.`}
    </Alert>
  );
};
