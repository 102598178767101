import { useEffect } from 'react';
import { env } from '@automata/utils';
import { useFeedbackWidget } from './useFeedbackWidget';
import { useFlag } from 'hooks/unleashHooks';
import useUserV2 from 'hooks/useUserV2';
import { useGetVersion } from '@automata/api/apiComponents';

export const FeedbackWidget = (): JSX.Element | null => {
  const { data: user } = useUserV2();
  const { data: version } = useGetVersion({});
  const isFeedbackEnabled = useFlag('fe.feedback');
  const { load, widget, setUser } = useFeedbackWidget();

  useEffect(() => {
    if (isFeedbackEnabled && !widget && version) {
      load({
        appVersion: env('NEXT_PUBLIC_VERSION') ?? 'Not set',
        apiVersion: version.open_api_version,
      });
    }
  }, [load, widget, isFeedbackEnabled, version]);

  useEffect(() => {
    if (user) setUser(user.userData);
  }, [setUser, user]);

  return null;
};
