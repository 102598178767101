import { MouseEvent, useCallback } from 'react';
import { Button } from '../Button';
import { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { tracking } from '@automata/utils';
import { Dict } from 'mixpanel-browser';

interface ExtendedTrackingProps {
  /**
   * [screen or dashboard].[optional category].[action]
   * e.g. deviceDashboard.control.start
   */
  trackLabel: string;
  /**
   * If Group or Device specific, use ID
   */
  trackProps?: Dict;
  onClick?: LoadingButtonProps['onClick'];
  loading?: boolean;
}

export type TrackingButtonProps = LoadingButtonProps & ExtendedTrackingProps;

export const TrackedButton = ({
  children,
  trackLabel,
  trackProps,
  onClick,
  loading,
  ...buttonProps
}: TrackingButtonProps): JSX.Element => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      tracking.track(trackLabel, trackProps);
    },
    [onClick, trackLabel, trackProps]
  );

  return (
    <Button
      data-cy={trackLabel}
      onClick={handleClick}
      loading={loading}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

TrackedButton.displayName = 'TrackedButton';
