import { TrackedIconButton } from '../TrackedIconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TrackingButtonProps } from '../TrackedIconButton';

export type DeleteButtonProps = Omit<TrackingButtonProps, 'trackLabel'> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  trackLabelVariant?: string;
};

export const DeleteButton = ({
  onClick,
  trackLabelVariant,
  sx,
  ...trackedIconButtonProps
}: DeleteButtonProps): JSX.Element | null => {
  const label = trackLabelVariant
    ? `${trackLabelVariant}-delete-button`
    : 'delete-button';

  return (
    <TrackedIconButton
      onClick={onClick}
      aria-label="delete-button"
      sx={sx}
      {...trackedIconButtonProps}
      trackLabel={label}
    >
      <DeleteOutlineIcon />
    </TrackedIconButton>
  );
};

DeleteButton.displayName = 'DeleteButton';
