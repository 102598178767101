import { ILogger, LoggingFunction } from './interface';

export type Dependencies = typeof dependencies;

export const dependencies = { console };

export class Logger implements ILogger {
  private readonly deps: Dependencies;
  private logger: Console;

  constructor(overrides?: Dependencies) {
    this.deps = { ...dependencies, ...overrides };
    this.logger = this.deps.console;
  }

  log: LoggingFunction = (...args) => {
    this.logger.log(...args);
  };

  info: LoggingFunction = (...args) => {
    this.logger.info(...args);
  };

  warn: LoggingFunction = (...args) => {
    this.logger.warn(...args);
  };

  error: LoggingFunction = (...args) => {
    this.logger.error(...args);
  };
}
