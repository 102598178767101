import EditOutlineIcon from '@mui/icons-material/EditOutlined';
import { TrackedIconButton, TrackingButtonProps } from '../TrackedIconButton';
import { forwardRef } from 'react';

export type EditButtonProps = Omit<
  TrackingButtonProps,
  'trackLabel' | 'aria-label' | 'onCLick'
> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  trackLabelVariant?: string;
};

export const EditButton = forwardRef<HTMLButtonElement, EditButtonProps>(
  (props, ref): JSX.Element | null => {
    const { onClick, trackLabelVariant, ...trackedIconButtonProps } = props;

    const label = trackLabelVariant
      ? `${trackLabelVariant}-edit-button`
      : 'edit-button';

    return (
      <TrackedIconButton
        {...trackedIconButtonProps}
        ref={ref}
        onClick={onClick}
        trackLabel={label}
        aria-label="edit-button"
      >
        <EditOutlineIcon />
      </TrackedIconButton>
    );
  }
);

EditButton.displayName = 'EditButton';
