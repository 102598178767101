import MuiPaper from '@mui/material/Paper';
import { PaperProps as MuiPaperProps } from '@mui/material/Paper';

interface PaperProps extends MuiPaperProps {
  bgVariant?: 'default' | 'paper';
}

export const Paper = ({
  bgVariant = 'default',
  children,
  ...rest
}: PaperProps): JSX.Element => {
  return (
    <MuiPaper
      sx={{ backgroundColor: bgVariant === undefined ? 'default' : bgVariant }}
      elevation={0}
      square
      {...rest}
    >
      {children}
    </MuiPaper>
  );
};
