import { TrackedIconButton } from '../TrackedIconButton';
import { DriverIcon } from './DriverIcon';

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  trackLabelVariant?: string;
}

export const DriverButton = ({
  onClick,
  trackLabelVariant,
}: Props): JSX.Element | null => {
  const label = trackLabelVariant
    ? `${trackLabelVariant}-driver-toggle`
    : 'driver-toggle';

  return (
    <TrackedIconButton
      onClick={onClick}
      trackLabel={label}
      aria-label="driver-button"
    >
      <DriverIcon />
    </TrackedIconButton>
  );
};

DriverButton.displayName = 'DriverButton';
