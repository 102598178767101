import { ILogger } from './interface';
import { isServerSide } from '../serverSide';
import { Logger as ServerSideLogger } from './serverSideLogger';
import { Logger as ClientSideLogger } from './clientSideLogger';

export type Dependencies = typeof dependencies;

export const dependencies = {
  isServerSide,
  ServerSideLogger,
  ClientSideLogger,
};

export const loggerFactory = (overrides?: Dependencies) => {
  const deps = { ...dependencies, ...overrides };

  if (deps.isServerSide()) {
    return new deps.ServerSideLogger();
  } else {
    return new deps.ClientSideLogger();
  }
};

export const logger: ILogger = loggerFactory();
