import { CSSProperties, ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  width?: CSSProperties['maxWidth'];
  element?: keyof JSX.IntrinsicElements;
}

export const TruncateText = ({
  children,
  width = '100%',
  element: Element = 'span',
}: Props) => {
  const title = typeof children === 'string' ? children : undefined;

  return (
    <Element
      style={{
        display: 'inline-block',
        maxWidth: width,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={title}
    >
      {children}
    </Element>
  );
};
