import { useState, useCallback } from 'react';
import markerSDK, {
  MarkerSdk,
  MarkerProjectWidgetParams,
} from '@marker.io/browser';
import { env } from '@automata/utils';

interface MetaData {
  apiVersion: string;
  appVersion: string;
}

export const useFeedbackWidget = () => {
  const [widget, setWidget] = useState<MarkerSdk>();

  const setUser = useCallback(
    ({ email, name }: { email: string; name: string }) => {
      if (widget) {
        widget.setReporter({ email, fullName: name });
      }
    },
    [widget]
  );

  const unload = useCallback(() => {
    if (!widget) return;

    widget.unload();
    setWidget(undefined);
  }, [widget]);

  const load = useCallback(
    async (metaData: MetaData) => {
      if (widget) {
        unload();
      }

      const settings: MarkerProjectWidgetParams = {
        project: env('NEXT_PUBLIC_MARKERIO_PROJECT'),
        customData: { ...metaData },
      };

      const _widget = await markerSDK.loadWidget(settings);

      setWidget(_widget);
    },
    [widget, unload]
  );

  return {
    load,
    setUser,
    widget,
  };
};
