import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export type SpinnerProps = {
  size?: number;
};

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
} satisfies SxProps<Theme>;

export const Spinner = ({ size = 18 }: SpinnerProps): JSX.Element => (
  <Box sx={styles.container}>
    <CircularProgress
      size={size}
      thickness={6}
      aria-label="circular progress"
    />
  </Box>
);
