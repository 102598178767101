import {
  useGetPermissions,
  useGetUser,
  useGetUserOrganizationsV2,
  useGetUserPermissions,
} from '@automata/api/apiComponents';
import {
  QueryResult,
  getErrorState,
  getLoadingState,
  getSuccessState,
} from '@automata/utils';
import {
  organizationsSelector,
  permissionsSelector,
  userPermissionsSelector,
  userSelector,
} from './selectors';
import { IUser } from './types';

interface Data {
  organizations: IUser['organizationsMap'];
  automataUser: IUser['userData'];
  permissions: IUser['permissions'];
  userPermissions: IUser['permissionSchema'];
}

const useQueries = (
  currentOrgId?: string,
  userPicture?: string
): QueryResult<Data, Array<unknown>> => {
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    error: organizationsError,
  } = useGetUserOrganizationsV2({}, { select: organizationsSelector });

  const {
    data: automataUser,
    isLoading: isAutomataUserLoading,
    error: automataUserError,
  } = useGetUser({}, { select: (data) => userSelector(data, userPicture) });

  const { data: userPermissions, error: userPermissionsError } =
    useGetUserPermissions(
      {
        pathParams: {
          organizationID: currentOrgId ?? '',
          userID: automataUser?.user_id ?? '',
        },
      },
      {
        enabled: !!currentOrgId && !!automataUser?.user_id,
        select: userPermissionsSelector,
      }
    );

  const {
    data: userPermissionsData,
    isLoading: isUserPermissionsDataLoading,
    error: userPermissionsDataError,
  } = useGetPermissions(
    {},
    { select: (data) => permissionsSelector(data, userPermissions) }
  );

  const {
    data: permissions,
    isLoading: isPermissionsLoading,
    error: permissionsError,
  } = useGetPermissions({}, { select: (data) => data.permissions });

  const errors = [
    userPermissionsDataError,
    userPermissionsError,
    organizationsError,
    automataUserError,
    permissionsError,
  ].filter(Boolean);

  if (
    userPermissionsDataError ||
    userPermissionsError ||
    organizationsError ||
    automataUserError ||
    permissionsError
  ) {
    return getErrorState(errors);
  }

  /*
   * useGetUserPermissions isLoading should be omitted
   * to allow a second hook render with the userPermissions data
   */
  if (
    isOrganizationsLoading ||
    isUserPermissionsDataLoading ||
    isAutomataUserLoading ||
    isPermissionsLoading
  ) {
    return getLoadingState();
  }

  return getSuccessState({
    organizations,
    automataUser,
    permissions: userPermissionsData,
    userPermissions: permissions,
  });
};

export default useQueries;
