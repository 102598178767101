import { Realtime } from 'ably';
import { AblyProvider as AblyClientProvider } from 'ably/react';
import { ReactNode } from 'react';
import { ablyAuthCallback } from './authCallback';

export const AblyProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const client = new Realtime.Promise({
    authCallback: ablyAuthCallback,
  });

  return <AblyClientProvider client={client}>{children}</AblyClientProvider>;
};
