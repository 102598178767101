import { env } from '@automata/utils';
import { EnvEnum } from './envEnum';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export const DD_PARAMS: Partial<RumInitConfiguration> = {
  site: 'datadoghq.eu',
  service: 'linq-platform',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
};

export const initialiseDatadog = () => {
  const ENV = env('NEXT_PUBLIC_ENV');
  const DD_APP_ID = env('NEXT_PUBLIC_DD_APP_ID');
  const DD_TOKEN = env('NEXT_PUBLIC_DD_TOKEN');
  const VERSION = env('NEXT_PUBLIC_VERSION');

  if (!DD_TOKEN) throw new Error('Missing Datadog token');
  if (!DD_APP_ID) throw new Error('Missing Datadog app ID');

  if (EnvEnum[ENV.toUpperCase() as keyof typeof EnvEnum] !== EnvEnum.LOCAL) {
    datadogRum.init({
      ...DD_PARAMS,
      applicationId: DD_APP_ID,
      clientToken: DD_TOKEN,
      env: ENV,
      version: VERSION,
    });

    datadogRum.startSessionReplayRecording();
  }
};
