import Stack, { StackProps } from '@mui/material/Stack';

export interface KeyValuePairProps {
  name: string;
  keyElement: React.ReactNode;
  valueElement: React.ReactNode;
  gap?: StackProps['gap'];
}

/**
 * Renders a description list as a single dt/dd couple.
 * @param keyElement The element to use as the definition title.
 * @param valueElement The element to display as the definition description.
 * @returns JSX.Element representing a description list.
 */
export const KeyValuePair = ({
  name,
  keyElement,
  valueElement,
  gap,
}: KeyValuePairProps): JSX.Element => {
  const shouldAddLineheight = typeof keyElement === 'string';
  const lineHeight = shouldAddLineheight ? 1.2 : 0;
  return (
    <Stack component="dl" aria-label={`${name} list`} m={0} gap={gap}>
      <dt aria-label={name} style={{ lineHeight }}>
        {keyElement}
      </dt>
      <dd aria-label={name} style={{ margin: 0, lineHeight }}>
        {valueElement}
      </dd>
    </Stack>
  );
};
