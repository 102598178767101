/**
 * get all environment variables set in process env
 * @returns
 */
export const getProcessEnv = () => process.env;

export type GetEnvDeps = typeof getEnvDeps;

const getEnvDeps = {
  getProcessEnv,
};

/**
 * get specific environment variable
 * can throw an error if environment variable is required and not set
 */
export const getEnv = (
  name: string,
  required = true,
  overrides?: GetEnvDeps
): string => {
  const deps = { ...getEnvDeps, ...overrides };

  const processEnv = deps.getProcessEnv();
  const val = processEnv[name] ?? '';
  if (!val && required) {
    throw `Environment variable ${name} must be set`;
  }
  return val;
};

/**
 * get optional environment variable
 */
export const getOptionalEnv = (
  name: string,
  overrides?: GetEnvDeps
): string | undefined => {
  const deps = { ...getEnvDeps, ...overrides };

  const processEnv = deps.getProcessEnv();
  return processEnv[name];
};
