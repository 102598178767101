import { TrackedButton } from './TrackedButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const styles = {
  iconRefresh: { color: '#fff' },
};

type Props = {
  children?: React.ReactNode;
};

export const Refresh = ({ children }: Props): JSX.Element => {
  return (
    <TrackedButton
      onClick={() => {
        return window.location.reload();
      }}
      trackLabel="refresh"
      color="inherit"
      variant="text"
    >
      {children}
      <RefreshIcon sx={styles.iconRefresh} />
    </TrackedButton>
  );
};
