import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { forwardRef } from 'react';

export type TypographyProps = MuiTypographyProps;

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  function forwarded(props, ref) {
    return <MuiTypography {...props} ref={ref} />;
  }
) as typeof MuiTypography;
