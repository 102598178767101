import React, { useState, useCallback, useMemo } from 'react';
import Modal from '@mui/material/Modal';
import { createId as cuid } from '@paralleldrive/cuid2';

interface ModalProviderProps {
  children: React.ReactNode;
}

type ModalCallback = () => void;
type ModalContent = (closeModal: ModalCallback) => JSX.Element;

interface IUseModal {
  openModal: (content: ModalContent) => void;
  currentModal: [string, ModalContent] | null;
  closeModal: (modalId: string) => void;
}

export const ModalContext = React.createContext<
  Omit<IUseModal, 'currentModal'>
>({
  openModal: () => {
    return '';
  },
  closeModal: () => {
    return undefined;
  },
});

export const useModal = (): IUseModal => {
  const [modals, setModals] = useState<Map<string, ModalContent>>(new Map());

  const currentModal = useMemo(() => {
    if (modals.size === 0) {
      return null;
    }

    const modalsArr = Array.from(modals);

    return modalsArr[modalsArr.length - 1];
  }, [modals]);

  const closeModal = useCallback(
    (modalId: string) => {
      if (modals.get(modalId)) {
        const updatedModals = new Map(modals);
        updatedModals.delete(modalId);

        setModals(updatedModals);
      }
    },
    [modals]
  );

  const openModal = useCallback(
    (content: ModalContent) => {
      const modalId = cuid();
      const updatedModals = new Map(modals);
      updatedModals.set(modalId, content);

      setModals(updatedModals);
    },
    [modals]
  );

  return {
    openModal,
    closeModal,
    currentModal,
  };
};

export const ModalProvider = ({
  children,
}: ModalProviderProps): JSX.Element => {
  const { openModal, closeModal, currentModal } = useModal();

  const executeModal = useCallback(() => {
    if (currentModal === null) {
      return <></>;
    }

    const [modalId, modalFunctionComponent] = currentModal;
    const closeModalCallback = () => {
      closeModal(modalId);
    };

    return modalFunctionComponent(closeModalCallback);
  }, [currentModal, closeModal]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {currentModal !== null && <Modal open>{executeModal()}</Modal>}
      {children}
    </ModalContext.Provider>
  );
};
