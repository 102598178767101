import { useContext } from 'react';
import { useFlag } from 'hooks/unleashHooks';
import AppBar from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/system/Stack';
import { ThemeContext } from 'contexts/ThemeContext';
import { ThemeModeSwitch } from 'components/ThemeModeSwitch';
import { ProfileNavItem } from './ProfileNavItem';
import dynamic from 'next/dynamic';
import { SettingsMenu } from 'components/SettingsMenu';
import { MainLinks } from 'components/Nav/MainLinks';
import LogoLinq from 'components/LogoLinq';
import Box from '@mui/material/Box';

const Notifications = dynamic(
  import('components/Notifications').then((mod) => {
    return mod.Notifications;
  }),
  {
    ssr: false,
  }
);

const styles = {
  appBar: (theme: Theme) => {
    return {
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.divider}`,
    };
  },
};

export const Nav = (): JSX.Element => {
  const { setMode, mode } = useContext(ThemeContext);
  const darkModeEnabled = useFlag('fe.dark_mode');
  const theme = useTheme();

  return (
    <AppBar position="fixed" elevation={0} sx={styles.appBar(theme)}>
      <Toolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Box display="flex" alignItems="baseline" flexGrow={1}>
              <LogoLinq
                width="125px"
                color={mode === 'light' ? '#022f58' : 'white'}
              />
            </Box>

            <MainLinks />
          </Stack>

          <Stack direction="row" alignItems="center">
            <SettingsMenu />

            <Notifications />
            <ProfileNavItem />

            {darkModeEnabled && (
              <ThemeModeSwitch
                onChange={() => {
                  return setMode(mode === 'light' ? 'dark' : 'light');
                }}
                checked={mode === 'dark'}
              />
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
