import { useUser } from '@auth0/nextjs-auth0/client';
import {
  QueryResult,
  getErrorState,
  getLoadingState,
  getSuccessState,
} from '@automata/utils';
import { useEffect, useMemo, useState } from 'react';
import { automataAuth0UserSchema } from './validation';
import { IUser } from './types';
import useQueries from './useQueries';
import {
  getCurrentOrganization,
  getDefaultOrganization,
  handleReauthenticate,
  handleSwitchOrganization,
} from './utils';

const useUserV2 = (): QueryResult<IUser, Array<unknown>> => {
  const [currentOrganization, setCurrentOrganization] = useState<
    IUser['currentOrganization'] | null
  >(null);
  const { user: rawAuth0User, error: auth0UserError } = useUser();

  const automataAuth0User = useMemo(() => {
    if (!rawAuth0User) return null;
    return automataAuth0UserSchema.parse(rawAuth0User);
  }, [rawAuth0User]);

  const { data, isLoading, error } = useQueries(
    currentOrganization?.id,
    automataAuth0User?.picture
  );

  useEffect(() => {
    if (data?.organizations && automataAuth0User) {
      setCurrentOrganization(
        getCurrentOrganization(data.organizations, automataAuth0User.org_id)
      );
    }
  }, [automataAuth0User, data?.organizations]);

  if (error) return getErrorState(error);
  if (auth0UserError) return getErrorState([auth0UserError]);
  if (isLoading || !automataAuth0User || !currentOrganization)
    return getLoadingState();

  const { automataUser, organizations, permissions, userPermissions } = data;

  const currentWorkspace = {
    id: currentOrganization.workspace,
    name: currentOrganization.name,
    organizationId: currentOrganization.id,
    slug: currentOrganization.slug,
  };
  const defaultOrganization = getDefaultOrganization(organizations);

  const switchOrganization: IUser['switchOrganization'] =
    handleSwitchOrganization;

  const reauthenticate: IUser['reauthenticate'] = (appState) =>
    handleReauthenticate(currentOrganization.externalID, appState);

  return getSuccessState({
    currentOrganization,
    currentWorkspace,
    organizationsMap: organizations,
    permissions,
    permissionSchema: userPermissions,
    userData: automataUser,
    defaultOrganization,
    switchOrganization,
    reauthenticate,
  });
};

export default useUserV2;
