/**
 * Returns a normalized version of the next/router query object.
 * Values are always strings, and if an array is passed, the first value is used.
 * @param query - The query object to normalize.
 * @returns The normalized query object.
 */
export const normalizeRouterQuery = (
  query: Record<string, string | string[] | undefined>
): Record<string, string> => {
  const initialVal: Record<string, string> = {};
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = value[0] || '';
    } else {
      acc[key] = `${value}`;
    }
    return acc;
  }, initialVal);
};
