import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

interface CentredNotificationCardProps {
  children: ReactNode;
}

const styles = {
  wrapper: {
    height: 'calc(100vh - 100px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 4,
  },
};

export const CentredNotificationCard = ({
  children,
}: CentredNotificationCardProps): JSX.Element => {
  return (
    <Box sx={styles.wrapper}>
      <Card>
        <Box sx={styles.content}>{children}</Box>
      </Card>
    </Box>
  );
};
