import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '../Typography';
import { KeyValuePair } from '../KeyValuePair';

const ValueBox = styled(Box)`
  background-color: ${(p) => p.theme.palette.grey[100]};
  padding: ${(p) => p.theme.spacing(1)};
  width: 100%;
`;

export type ReplanPopoverContentProps = {
  title: string;
  content: string;
  previousDataHeader: string;
  previousDataContent: string;
  currentDataHeader: string;
  currentDataContent: string;
  direction?: 'column' | 'row';
};

export const ReplanPopoverContent = ({
  title,
  content,
  previousDataHeader,
  previousDataContent,
  currentDataHeader,
  currentDataContent,
  direction = 'column',
}: ReplanPopoverContentProps) => {
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{content}</Typography>
      </Stack>
      <Stack gap={2} direction={direction}>
        <KeyValuePair
          keyElement={
            <Typography variant="overline" noWrap>
              {previousDataHeader}
            </Typography>
          }
          valueElement={
            <ValueBox>
              <Typography variant="h6">{previousDataContent}</Typography>
            </ValueBox>
          }
          name={previousDataHeader}
          gap={1}
        />
        <KeyValuePair
          keyElement={
            <Typography variant="overline" noWrap>
              {currentDataHeader}
            </Typography>
          }
          valueElement={
            <ValueBox>
              <Typography variant="h6">{currentDataContent}</Typography>
            </ValueBox>
          }
          name={currentDataHeader}
          gap={1}
        />
      </Stack>
    </Stack>
  );
};
