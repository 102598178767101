import { ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import { PaperProps, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Drawer from '@mui/material/Drawer';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { defaultTheme } from '@automata/mui-theme';

interface SidebarProps {
  open?: boolean;
  maximized?: boolean;
  onClose?: () => void;
  onMaximize?: () => void;
  onMinimize?: () => void;
  children?: ReactNode;
  sx?: SxProps;
  PaperProps?: PaperProps;
  title?: string | ReactNode;
}

const IconButtonStyled = styled(IconButton)`
  background-color: ${(p) => p.theme.palette.background.default};
  &:hover {
    background-color: ${(p) => p.theme.palette.grey[100]};
  }
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled(Stack)`
  align-items: 'center';
  color: ${(p) => p.theme.palette.grey[700]};
  height: '32px';
  z-index: 1;
  background-color: ${(p) => p.theme.palette.background.default};
  position: sticky;
  top: 0;
  right: 0;
`;

const ContentWrap = styled('div')`
  position: relative;
`;

const Content = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: '308px';
  overflow-y: 'auto !important';
  &.maximized {
    width: '600px';
    margin: '0 auto !important';
    overflow-y: 'auto';
  }
`;

export const Sidebar = ({
  open = false,
  maximized,
  onClose,
  onMaximize,
  onMinimize,
  children,
  PaperProps = { sx: {} },
  sx,
  title,
}: SidebarProps) => {
  return (
    <Drawer
      variant="persistent"
      open={open}
      PaperProps={{
        ...PaperProps,
        sx: {
          width: maximized ? '100%' : '392px',
          position: maximized ? 'absolute' : 'relative',
          ...PaperProps.sx,
        },
      }}
      SlideProps={{
        easing: {
          enter: defaultTheme.transitions.easing.easeOut,
          exit: defaultTheme.transitions.easing.sharp,
        },
        timeout: {
          enter: defaultTheme.transitions.duration.complex,
          exit: defaultTheme.transitions.duration.leavingScreen,
        },
      }}
      sx={sx}
    >
      <Container>
        <Header
          data-testid="sidebar-header"
          direction="row"
          justifyContent={title ? 'space-between' : 'flex-end'}
        >
          {title}
          <Box>
            {onMaximize && !maximized && (
              <IconButtonStyled
                aria-label="maximize"
                size="small"
                onClick={onMaximize}
                data-testid="maximize-Sidebar"
              >
                <OpenInFullIcon />
              </IconButtonStyled>
            )}
            {onMinimize !== undefined && maximized && (
              <IconButtonStyled
                aria-label="minimize"
                size="small"
                onClick={onMinimize}
                data-testid="minimize-Sidebar"
              >
                <CloseFullscreenIcon />
              </IconButtonStyled>
            )}
            <IconButtonStyled
              aria-label="close"
              size="small"
              onClick={onClose}
              data-testid="close-Sidebar"
            >
              <CloseIcon />
            </IconButtonStyled>
          </Box>
        </Header>
        <ContentWrap>
          <Content className={maximized ? 'maximized' : ''}>{children}</Content>
        </ContentWrap>
      </Container>
    </Drawer>
  );
};
