import { Organization, User, Permission } from '@automata/api/apiSchemas';

export enum PermissionsEnum {
  ADMIN_RESOURCES = 'admin_resources',
  RUN_RESOURCES = 'run_resources',
  RUN_OPERATIONS = 'run_operations',
  NO_ACCESS = 'no_access',
}

export enum PermissionLevelsEnum {
  WRITE = 'write',
  READ = 'read',
  EXECUTE = 'execute',
  NONE = 'none',
}

export type PermissionInfo = {
  label: string;
  description: string;
  level: PermissionLevelsEnum;
};

export type UserPermissionsData = Map<PermissionsEnum, PermissionInfo>;

interface Workspace {
  id: string;
  name: string;
  organizationId: string;
  slug: string;
}

export type AppState = Record<string, string> & {
  requestIntention?: string;
  redirectPath?: string;
};

export interface IUser {
  organizationsMap: Map<string, Organization>;
  currentOrganization: Organization;
  permissions: UserPermissionsData;
  userData: User;
  permissionSchema: Permission[];
  defaultOrganization: Organization;
  currentWorkspace: Workspace;
  switchOrganization: (externalOrgId: string) => void;
  reauthenticate: (appState?: AppState) => Promise<void>;
}
